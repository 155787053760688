body {
  margin: 0;
  font-family: "Trebuchet MS";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#wrapper {
  width: 100%;
  height: 100%;
  top: 0;
    bottom:0;
    position:fixed;
    overflow-y:scroll;
    overflow-x:hidden;
}

#profile-editor-container {
  margin: 0 auto;
}

#content {
  padding: 10px;
}



  #profile-viewer-content {
    margin: 0 auto;
  }


@media only screen 
and (max-width : 3000px){
  #profile-viewer-content {
    padding: 10px;
    width: 50vw;
  }
}

@media only screen 
and (max-width : 1500px){
  #profile-viewer-content {
    padding: 10px;
    width: 80vw;
  }
}

@media only screen 
and (max-width : 1080px){
  #profile-viewer-content {
    padding: 10px;
    width: 80vw;
  }
}

@media only screen 
and (max-width : 700px){
  #profile-viewer-content {
    padding: 10px;
    width: 80vw;
    }

}
#profile-viewer-content {
  padding: 10px;
  max-width: 80vw;
}

#profile-viewer-toolbar {
  position: relative;
  float: left;
  width: 450px;
}

#left-content {
  max-width: 600px;
}

#right-content {
  width: 320px;
}

.vk-profile-picture {
  max-width: 150px;
  width: 150px;
  height: 150px;
  float: left;
}

.vk-profile-picture-image {
  max-width: 150px;
  height: 150px;
}

#personal-info-content {
  border: 1px solid #000;
  overflow: hidden;
  max-height: 100px;
}

#vk-crop-tool-container {
  background-color: "red";
}

#vk-crop-tool-preview {
  margin-left: 250px;
  text-align: center;
}
#vk-cropped-image-preview {
  width: 300px;
  height: 300px;
  margin: auto;
  border-radius: 150px;
}

#vk-crop-tool-controls {
  height: 100%;
  width: 250px;
  background-color: white;
  position: absolute;
  z-index: 999;
  padding-left: 15px;
  padding-right: 15px;
  top: 0;
  left: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.info-box-hidden {
  opacity: 0.0;
  background-color:#fff;
  border:1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 14px;
}

@keyframes animate-show-info-box {
  from {
    opacity: 0.0;
  }
  to {
    opacity: 1.0;
  }
}


@keyframes animate-hide-info-box {
  from {
    opacity: 1.0;
  }
  to {
    opacity: 0.0;
  }
}

.info-box-show-animation {
  animation-name: animate-show-info-box;
  animation-duration: 1s;
  animation-fill-mode:forwards;
}

.info-box-hide-animation {
  animation-name: animate-hide-info-box;
  animation-duration: 1s;
  animation-fill-mode:forwards;
}

#personal-details-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


@media only screen 
and (min-width : 701px){
  #personal-details-container {
    max-width: 400px;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
  }

  #personal-details-container h3 {
    font-size: 26px;
    font-weight: bold;
    margin:0;
  }

  #personal-details-container h4 {
    font-size: 20px;
    font-weight: bold;
    margin:0;
  }

.skill-block-item-row {
  display: flex;
  flex: 1;
  flex-direction: row;
}
}

@media only screen 
and (max-width : 700px){
  #personal-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  #personal-details-container h3 {
    font-size: 5vw;
    font-weight: bold;
    margin:10px 0 0 0;
  }

  #personal-details-container h4 {
    font-size: 4vw;
    font-weight: bold;
    margin:5px 0 0 0;
  }

.skill-block-item-row {
  display: flex;
  flex: 1;
  flex-direction: column;
}
}

.group-result-container {
  display: flex;
  flex-direction: row;
  margin-top:25px;
}


@media only screen 
and (max-width : 1240px){
  .group-result-container {
    display: flex;
    flex-direction: column;
  }
}




.competition-step-box {
  padding: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width:250px;
  height: 300px;
  text-align: left;
  color:#32213A;
  border-radius: 10px;
}

.competition-step-box label {
  font-size: 14px;
}


.competition-step-box h4 {
  margin:0; 
}