.control-label {
  font-size: 14px;
  font-weight: 600;
}

.month-year-select {
}

.editor-row {
  margin-top: 10px;
  padding: 5px;
}

.button-editor-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.primary-button {
  background-color: #000;
  padding: 5px 15px 5px 15px;
  border: none;
  color: #fff;
  font-weight: 800;
  border-radius: 3px;
}

.primary-button:hover {
  background-color: #fff;
  padding: 5px 15px 5px 15px;
  border: 1px solid #000;
  font-weight: 800;
  cursor: pointer;
  color: #000;
  border-radius: 3px;
}

.secondary-button {
  background-color: #fff;
  padding: 4px 14px 4px 14px;
  border: 1px solid #000;
  color: #000;
  border-radius: 3px;
}
  
.secondary-button:hover {
  background-color: #000;
  padding: 4px 14px 4px 14px;
  border: 1px solid #000;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
}

.danger-button {
  background-color: red;
  padding: 4px 14px 4px 14px;
  border: 1px solid #000;
  color: #fff;
  border-radius: 3px;
}

.danger-button:hover {
  background-color: darkred;
  cursor: pointer;
  padding: 4px 14px 4px 14px;
  border: 1px solid #000;
  color: #fff;
  border-radius: 3px;
}

.link-button {
  background-color: transparent;
  padding: 4px 14px 4px 14px;
  border: none;
  color: #000;
}

.white-link-button {
  background-color: transparent;
  padding: 4px 14px 4px 14px;
  border: none;
  color: #fff;
}

.white-link-button:hover {
  background-color: transparent;
  padding: 4px 14px 4px 14px;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  color: #e3e3e3;
}

.link-button-lg {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  padding: 0px;
}

.dark-mode {
  color: "#fff";
}

.button-lg {
  width: 250px;
  height: 40px;
}

.link-button:hover {
  text-decoration: underline;
}

.danger {
  color: red;
}

.score-button {
  border: 1px solid #32213A;
  width: 25px;
  color: #32213A;
  font-size: 18px;
  height: 25px;
  background-color: transparent;
  border-radius: 12.5px;
}

